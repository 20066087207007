import axios from "axios";

const baseLink = 'https://dev.depoyazilim.com/api';
//const baseLink = "http://localhost:8000/api";
const instance = axios.create({ baseURL: baseLink, withCredentials: false });
instance.interceptors.request.use(async function (config) {
  const token = localStorage.getItem("token");
  if (token) config.headers["Authorization"] = "Bearer " + token;
  return config;
});

export default instance;
